<template>
  <div>
    <ds-header title="积分返还管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="规则ID：">
        <a-input v-model.trim="searchForm.sceneRuleId" placeholder="请输入规则ID" class="inputClass" />
      </a-form-item>
      <a-form-item label="积分类型：">
        <a-select
          style="width: 200px"
          placeholder="请选择积分类型"
          v-model="searchForm.sceneRuleType"
        >
          <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="规则名称：">
        <a-input v-model.trim="searchForm.sceneRuleName" placeholder="请输入规则名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          style="width: 200px"
          placeholder="请选择状态"
          v-model="searchForm.status"
        >
          <a-select-option v-for="item in ruleStatusList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)" v-if="record.status === 0"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.status === 0">启用</span>
            <span v-else>禁用</span>
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getIntegralReturnRatioList, integralReturnRatioDisableEnable } from '@/api/integralManagement'
export default {
  name: 'ruleManagement',
  data() {
    return{
      // 检索
      searchForm: {
        // 场景规则ID
        sceneRuleId: '',
        // sceneRuleType
        sceneRuleType: undefined,
        // 场景规则名称
        sceneRuleName: '',
        // 状态
        status: undefined
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "规则ID",
            dataIndex: "sceneRuleId",
          },
          {
            title: "所属项目",
            dataIndex: "projectName",
          },
          {
            title: "积分类型",
            dataIndex: "sceneRuleType",
            customRender(text) {
              return !text ? '消费积分' : ''
            },
          },
          {
            title: "规则名称",
            dataIndex: "sceneRuleName",
          },
          {
            title: "用户身份限制",
            dataIndex: "restrictType",
            customRender(text) {
              return text ? '企业认证' : '无限制'
            }
          },
          {
            title: "状态",
            dataIndex: "status",
            customRender(text) {
              return text ? '启用' : '停用'
            },
          },
          {
            title: "更新时间",
            dataIndex: "updateTime",
          },
          {
            title: "操作人",
            dataIndex: "updateUserName",
          },
          {
            title: "操作",
            scopedSlots: { customRender: "action" },
          }
        ],
        pagination: {
          total: 0,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        }
      },
      //
      ruleStatusList: [
        {
          label: '启用',
          value: 1
        },
        {
          label: '停用',
          value: 0
        }
      ],
      // 规则类型list
      ruleTypeList: [
        {
          label: '消费积分',
          value: 0
        }
      ]
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    // 获取积分返还比列表
    async getList() {
      const params = this.getParams()
      const res = await getIntegralReturnRatioList(params);
      const { data: { data, count }, code } = res
      if (code === '200') {
        this.table.dataInfo = data;
        this.table.pagination.total = count;
      }
    },
    // 参数
    getParams() {
      return {
        ...this.searchForm,
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize
      }
    },

    // 检索
    search() {
      this.table.pagination.current = 1
      this.getList()
    },

    // 重置
    resetSearchForm() {
      this.searchForm.sceneRuleId = ''
      this.searchForm.sceneRuleName = ''
      this.searchForm.sceneRuleType = undefined
      this.searchForm.status = undefined
      this.table.pagination.current = 1
      this.table.pagination.pageSize = 10
      this.getList()
    },

    // 新增
    add() {
      this.$router.push({
        path: '/integralManagement/ruleManagement/add'
      })
    },
    // 编辑
    edit(data) {
      this.$router.push({
        path: '/integralManagement/ruleManagement/add',
        query: {
          id: data.sceneRuleId,
          type: 'edit'
        }
      })
    },
    // 查看
    view(data) {
      this.$router.push({
        path: '/integralManagement/ruleManagement/add',
        query: {
          id: data.sceneRuleId,
          type: 'view'
        }
      })
    },

    // 分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 启用禁用
    restart(record) {
      let that = this;
      let status = ''
      let title = ''
      if (record.status === 0) {
        title = "确定启用该【消费积分】数据吗？"
        status = 1;
      } else {
        title = "确定禁用该【消费积分】数据吗？"
        status = 0;
      }
      this.$confirm({
        title: title,
        onOk() {
          const id = record.sceneRuleId;
          integralReturnRatioDisableEnable(id, status).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.page = 1
              that.getList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
