import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"


/**
  * @description: 积分账户管理列表
  * @author: cxd
  * @time: 2023/9/30
 **/
export const getIntegralAccountList = (data) => {
    return fetchApi(URL.GET_INTEGRAL_ACCOUNT_LIST, data, 'GET', 'params', true);
}

/**
  * @description: 当前用户积分详情
  * @author: cxd
  * @time: 2023/9/30
 **/
export const getCurrentUserIntegralInfo = (data) => {
    return fetchApi(URL.GET_CURRENT_USER_INTEGRAL_INFO, data, 'GET', 'params' )
}

/**
  * @description:
  * @author: cxd
  * @time: 2023/10/1
 **/
export const saveIntegralReturnRatio = (data) => {
    return fetchApi(URL.SAVE_INTEGRAL_RETURN_RATIO, data, 'POST', 'data')
}

/**
  * @description:
  * @author: cxd
  * @time: 2023/10/1
 **/
export const editIntegralReturnRatio = (data) => {
    return fetchApi(URL.EDIT_INTEGRAL_RETURN_RATIO, data, 'PUT', 'data')
}

/**
  * @description: 积分返还比列表
  * @author: cxd
  * @time: 2023/10/1
 **/
export const getIntegralReturnRatioList = (data) => {
    return fetchApi(URL.GET_INTEGRAL_RETURN_RATIO_LIST, data, 'GET', 'params')
}

/**
  * @description: 积分返还比的启用和禁用
  * @author: cxd
  * @time: 2023/10/1
 **/
export const integralReturnRatioDisableEnable = (id, status) => {
    const data = {
        id,
        status
    }
    return fetchApi(URL.INTEGRAL_RETURN_RATIO_DISABLED_ENABLE, data, 'PUT', 'data')
}

/**
  * @description:
  * @author: cxd
  * @time: 2023/10/1
 **/
export const getIntegralReturnRatioById = (data) => {
    return fetchApi(URL.GET_INTEGRAL_RETURN_RATIO_BY_ID, data, 'GET', 'params')
}